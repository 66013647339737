import browser from "@raiden/library-ui/constants/browser";
import {
  CAMPAIGNS_SORT_VALUE_RANDOM,
  CAMPAIGNS_TYPE_VALUE_DESTINATION,
  CAMPAIGNS_TYPE_VALUE_THEMATIC,
} from "@raiden/library-ui/constants/campaigns";
import {
  POSTS_STATE_VALUE_PUBLISHED,
  POSTS_TYPE_VALUE_ARTICLE,
  POSTS_TYPE_VALUE_PAGE,
} from "@raiden/library-ui/constants/posts";
import { RESOURCES_VISIBILITY_VALUE_PUBLIC } from "@raiden/library-ui/constants/resources";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import { serverApiFetcher } from "@raiden/library-ui/hooks/useApiFetcher";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";
import { useMemo } from "react";
import Seo from "../components/Seo";
import { HomePublic } from "../containers/HomePublic";
import { getConfigurationCampaignCategoryByAlias } from "../helpers/configuration/getConfigurationCampaignCategoryByAlias";
import { getUrlCampaignsTypeDestination } from "../components/Campaigns/ShowcaseDestination/index.js";

/**
 *
 * @param {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Configuration").Configuration>} [configuration]
 * @returns {import("@raiden/library-ui/types/CampaignCategory").CampaignCategory[]}
 */
function getCampaignCategoriesTypeDestination(configuration) {
  return (
    configuration?.data?.campaigns_categories?.[
      CAMPAIGNS_TYPE_VALUE_DESTINATION
    ].filter(
      (campaignCategory) =>
        campaignCategory.department_id.length > 0 &&
        campaignCategory.is_federal,
    ) ?? []
  );
}

function getUrlConfigurationHomepage() {
  return generateApiUrl({
    id: "@configuration",
    query: {
      fields: ["homepage", "campaigns_categories"],
    },
  });
}

function getUrlPostsTypeArticle() {
  return generateApiUrl({
    id: "@posts.viewAny",
    query: {
      type: [POSTS_TYPE_VALUE_ARTICLE],
      without_departments: true,
      visibility: RESOURCES_VISIBILITY_VALUE_PUBLIC,
      per_page: 4,
      state: [POSTS_STATE_VALUE_PUBLISHED],
      fields: [
        "post.current_revision",
        "post.revision.cover",
        "post.categories",
      ],
    },
  });
}

/**
 * @param {object} params
 * @param {number} params.categoryId
 */
function getUrlPostsTypePage({ categoryId }) {
  return generateApiUrl({
    id: "@posts.viewAny",
    query: {
      type: [POSTS_TYPE_VALUE_PAGE],
      category_id: [categoryId],
      per_page: 4,
      fields: ["post.current_revision", "post.revision.cover"],
    },
  });
}

/**
 * @param {object} params
 * @param {number[]} params.withoutCategoryId
 */
function getUrlCampaignsTypeThematic({ withoutCategoryId }) {
  return generateApiUrl({
    id: "@campaigns.viewAny",
    query: {
      type: CAMPAIGNS_TYPE_VALUE_THEMATIC,
      sort: CAMPAIGNS_SORT_VALUE_RANDOM,
      per_page: 20,
      is_federal: true,
      without_category_id: withoutCategoryId,
      fields: ["campaigns.cover"],
    },
  });
}

/**
 * @param {object} params
 * @param {number} params.categoryId
 */
function getUrlCampaignsCategorySpecialOffers({ categoryId }) {
  return generateApiUrl({
    id: "@campaigns.viewAny",
    query: {
      category_id: [categoryId],
      type: CAMPAIGNS_TYPE_VALUE_THEMATIC,
      per_page: 16,
      fields: ["campaigns.cover"],
    },
  });
}

/**
 * @param {object} param0
 * @param {string} [param0.postAlias]
 * @returns {string}
 */
function getPostBlockSeo({ postAlias }) {
  return generateApiUrl({
    id: "@posts.show",
    parameters: {
      postId: postAlias,
    },
    query: {
      fields: ["post.current_revision"],
    },
  });
}

// #region component
/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Configuration").Configuration>} [configurationHomepageResponse]
 * @property {number} [slideshowItemIndex]
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post[]>} [articlesResponse]
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post[]>} [pagesResponse]
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Campaign").Campaign[]>} [campaignsTypeThematicResponse]
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Campaign").Campaign[]>} [campaignsTypeDestinationResponse]
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Slideshow").Slideshow>} [slideshowResponse]
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Campaign").Campaign[]>} [campaignsCategorySpecialOffersResponse]
 * @property {import("@raiden/library-ui/types/CampaignCategory").CampaignCategory[]} [campaignCategoriesTypeDestination]
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post>} [blockSeoResponse]
 * @property {number} [initialCategoryId]
 */

/**
 * @param {Props} props
 */
function Page({
  configurationHomepageResponse,
  slideshowItemIndex,
  articlesResponse,
  pagesResponse,
  campaignsTypeThematicResponse,
  campaignsTypeDestinationResponse,
  campaignsCategorySpecialOffersResponse,
  initialCategoryId,
  campaignCategoriesTypeDestination,
  blockSeoResponse,
}) {
  const { configuration } = useConfiguration();

  const postCategoryHomePage = useMemo(() => {
    return configuration?.posts_categories?.page?.find(
      (category) =>
        category.alias === process.env.NEXT_PUBLIC_POST_CATEGORY_HOMEPAGE_ALIAS,
    );
  }, [configuration?.posts_categories?.page]);

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Configuration").Configuration>} */
  const { swrResponse: swrResponseConfigurationHomepage } = useApi(
    configurationHomepageResponse === undefined
      ? getUrlConfigurationHomepage()
      : null,
    {
      swrConfig: {
        fallbackData: configurationHomepageResponse,
      },
    },
  );

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Post").Post[]>} */
  const { swrResponse: swrResponsePostsTypeArticle } = useApi(
    articlesResponse === undefined ? getUrlPostsTypeArticle() : null,
    {
      swrConfig: {
        fallbackData: articlesResponse,
      },
    },
  );

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Post").Post[]>} */
  const { swrResponse: swrResponsePostsTypePage } = useApi(
    pagesResponse === undefined && postCategoryHomePage !== undefined
      ? getUrlPostsTypePage({ categoryId: postCategoryHomePage.id })
      : null,
    {
      swrConfig: {
        fallbackData: pagesResponse,
      },
    },
  );

  const campaignCategorySpecialOffers = useMemo(() => {
    if (
      !swrResponseConfigurationHomepage.data?.data ||
      !process.env.NEXT_PUBLIC_CAMPAIGN_ALIAS_SPECIAL_OFFERS
    ) {
      return;
    }

    return getConfigurationCampaignCategoryByAlias({
      configuration: swrResponseConfigurationHomepage.data?.data,
      alias: process.env.NEXT_PUBLIC_CAMPAIGN_ALIAS_SPECIAL_OFFERS,
    });
  }, [swrResponseConfigurationHomepage.data?.data]);

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Campaign").Campaign[]>} */
  const { swrResponse: swrResponseThematics } = useApi(
    campaignsTypeThematicResponse === undefined
      ? getUrlCampaignsTypeThematic({
          withoutCategoryId: campaignCategorySpecialOffers
            ? [campaignCategorySpecialOffers.id]
            : [],
        })
      : null,
    {
      swrConfig: {
        fallbackData: campaignsTypeThematicResponse,
      },
    },
  );

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Campaign").Campaign[]>} */
  const { swrResponse: swrResponseCampaignsCategorySpecialOffers } = useApi(
    campaignsCategorySpecialOffersResponse === undefined &&
      campaignCategorySpecialOffers
      ? getUrlCampaignsCategorySpecialOffers({
          categoryId: campaignCategorySpecialOffers.id,
        })
      : null,
    {
      swrConfig: {
        fallbackData: campaignsCategorySpecialOffersResponse,
      },
    },
  );

  const _campaignCategoriesTypeDestination =
    campaignCategoriesTypeDestination ??
    getCampaignCategoriesTypeDestination(
      swrResponseConfigurationHomepage?.data,
    );
  const _initialCategoryId =
    initialCategoryId ?? _campaignCategoriesTypeDestination[0]?.id;

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Post").Post>} */
  const { swrResponse: swrResponseBlockSeo } = useApi(
    blockSeoResponse === undefined
      ? getPostBlockSeo({
          postAlias: process.env.NEXT_PUBLIC_POST_PAGE_FOOTER_BLOCK_SEO_ALIAS,
        })
      : null,
    {
      swrConfig: {
        fallbackData: blockSeoResponse,
      },
    },
  );

  return (
    <>
      <Seo
        links={(locale) =>
          generateFrontUrl({
            id: "@front.home",
            parameters: {
              locale,
            },
          })
        }
      />

      <HomePublic
        swrResponseConfigurationHomepage={swrResponseConfigurationHomepage}
        slideshowItemIndex={slideshowItemIndex}
        swrResponsePostsTypeArticle={swrResponsePostsTypeArticle}
        swrResponseCampaignsTypeThematic={swrResponseThematics}
        swrResponsePostsTypePage={swrResponsePostsTypePage}
        campaignsTypeDestinationResponse={campaignsTypeDestinationResponse}
        swrResponseCampaignsCategorySpecialOffers={
          swrResponseCampaignsCategorySpecialOffers
        }
        initialCategoryId={_initialCategoryId}
        campaignCategoriesTypeDestination={_campaignCategoriesTypeDestination}
        swrResponseBlockSeo={swrResponseBlockSeo}
      />
    </>
  );
}

export default Page;

// #region getInitialProps
/**
 * @typedef {import("../types/Page").PageInitialProps & Props} InitialProps
 */
/**
 * @param {import("../types/AppContext").PageContext} context
 * @returns {Promise<InitialProps>}
 */
Page.getInitialProps = async function (context) {
  /** @type {InitialProps} */
  const values = {
    layout: "public",
  };

  if (!browser) {
    const postCategoryHomePage =
      context.configuration?.posts_categories?.page?.find(
        (category) =>
          category.alias ===
          process.env.NEXT_PUBLIC_POST_CATEGORY_HOMEPAGE_ALIAS,
      );

    if (postCategoryHomePage) {
      /** @type {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post[]>} */
      values.pagesResponse = await serverApiFetcher(
        getUrlPostsTypePage({ categoryId: postCategoryHomePage?.id }),
      ).catch(() => undefined);
    }

    /** @type {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Configuration").Configuration>} */
    values.configurationHomepageResponse = await serverApiFetcher(
      getUrlConfigurationHomepage(),
    ).catch(() => undefined);

    const slideshow =
      values.configurationHomepageResponse?.data.homepage?.slideshow;
    if (slideshow && slideshow.items && slideshow.items.length > 0) {
      values.slideshowItemIndex = Math.floor(
        Math.random() * slideshow.items.length,
      );
    }

    /** @type {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post[]>} */
    values.articlesResponse = await serverApiFetcher(
      getUrlPostsTypeArticle(),
    ).catch(() => undefined);

    const campaignCategorySpecialOffers =
      process.env.NEXT_PUBLIC_CAMPAIGN_ALIAS_SPECIAL_OFFERS &&
      values.configurationHomepageResponse?.data
        ? getConfigurationCampaignCategoryByAlias({
            configuration: values.configurationHomepageResponse.data,
            alias: process.env.NEXT_PUBLIC_CAMPAIGN_ALIAS_SPECIAL_OFFERS,
          })
        : undefined;

    values.campaignsTypeThematicResponse = await serverApiFetcher(
      getUrlCampaignsTypeThematic({
        withoutCategoryId: campaignCategorySpecialOffers
          ? [campaignCategorySpecialOffers.id]
          : [],
      }),
    ).catch(() => undefined);

    values.campaignCategoriesTypeDestination =
      getCampaignCategoriesTypeDestination(
        values.configurationHomepageResponse,
      );

    values.initialCategoryId = values.campaignCategoriesTypeDestination[0]?.id;

    if (values.initialCategoryId) {
      values.campaignsTypeDestinationResponse = await serverApiFetcher(
        getUrlCampaignsTypeDestination({
          isFederal: true,
          categoryId: values.initialCategoryId,
        }),
      ).catch(() => undefined);
    }

    if (
      process.env.NEXT_PUBLIC_CAMPAIGN_ALIAS_SPECIAL_OFFERS &&
      values.configurationHomepageResponse?.data
    ) {
      if (campaignCategorySpecialOffers) {
        values.campaignsCategorySpecialOffersResponse = await serverApiFetcher(
          getUrlCampaignsCategorySpecialOffers({
            categoryId: campaignCategorySpecialOffers.id,
          }),
        ).catch(() => undefined);
      }
    }

    if (process.env.NEXT_PUBLIC_POST_PAGE_FOOTER_BLOCK_SEO_ALIAS) {
      /** @type {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post>} */
      values.blockSeoResponse = await serverApiFetcher(
        getPostBlockSeo({
          postAlias: process.env.NEXT_PUBLIC_POST_PAGE_FOOTER_BLOCK_SEO_ALIAS,
        }),
      ).catch(() => undefined);
    }
  }

  return values;
};

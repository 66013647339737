import { Box } from "@chakra-ui/react";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import { useIntl } from "react-intl";
import { CenteredContent } from "../../../../components/CenteredContent";
import { Content } from "../../../../components/Posts/Content";
import { SectionPublic } from "../../../../components/SectionPublic";

/**
 * @typedef {object} Props
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Post").Post>>} swrResponseBlockSeo
 */

/**
 * @param {Props} props
 */
export function BlockSeo({ swrResponseBlockSeo }) {
  const intl = useIntl();

  return (
    <SectionPublic
      title={intl.formatMessage({
        defaultMessage: "Retrouvez tous nos hébergements",
      })}>
      <DataHandler swrResponse={swrResponseBlockSeo}>
        {({ data: postBlockSeo }) => (
          <Box px={{ base: "0", md: "1rem" }}>
            <CenteredContent
              maxWidth={({ defaultMaxWidth }) =>
                `calc(${defaultMaxWidth} + 2rem)`
              }>
              <Content content={postBlockSeo.current_revision?.content} />
            </CenteredContent>
          </Box>
        )}
      </DataHandler>
    </SectionPublic>
  );
}

import {
  AspectRatio,
  Box,
  Button,
  DarkMode,
  IconButton,
  LightMode,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import { useSlider } from "@raiden/library-ui/components/Slider";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import { useIntl } from "react-intl";
import { CenteredContent } from "../../../components/CenteredContent";
import { Href } from "../../../components/Href";
import { TEXT_SHADOW_OUTLINE } from "../../../constants/textShadows";

const SX_GRADIENT = {
  "@media screen and (min-width: 1360px)": {
    maskImage:
      "linear-gradient(to left, transparent 0%, black 1%, black 99%, transparent 100%)",
  },
};

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Slideshow").SlideshowItem} [slideshowItem]
 * @property {number} countSlides
 */
/**
 * @param {Props} props
 */
export function SlideshowItem({ slideshowItem, countSlides }) {
  const translate = useTranslate();

  const title = translate(slideshowItem?.title);

  const subtitle = translate(slideshowItem?.subtitle);

  const ctaLabel = translate(slideshowItem?.cta_label);

  const ctaUrl = slideshowItem?.cta_url;

  const intl = useIntl();

  const { handleClickNext, handleClickPrev } = useSlider();

  return (
    <Box position="relative" overflow="hidden">
      <CenteredContent maxWidth="full">
        <ConditionalWrapper
          hasWrapper={Boolean(ctaUrl && ctaLabel === undefined)}
          wrapper={(children) => <Href href={ctaUrl ?? ""}>{children}</Href>}>
          <Box position="relative">
            <AspectRatio
              ratio={1360 / 425}
              display={{ base: "none", md: "block" }}>
              <PampaImage
                image={slideshowItem?.cover}
                mode="crop"
                format="default"
                w="full"
                loading="eager"
                alt=""
              />
            </AspectRatio>

            <AspectRatio ratio={1} display={{ base: "block", md: "none" }}>
              <PampaImage
                image={slideshowItem?.cover}
                mode="crop"
                format="mobile"
                w="full"
                loading="eager"
                alt=""
              />
            </AspectRatio>

            {/* overlay */}

            <Box
              position="absolute"
              inset="0"
              background="linear-gradient(90deg, #002538 28.36%, rgba(0, 37, 56, 0.00) 77.77%)"
              opacity=".2"
              pointerEvents="none"
              sx={SX_GRADIENT}
            />

            <DarkMode>
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                px="1.25rem">
                <CenteredContent>
                  <Stack spacing="1rem" justify="center" h="full">
                    {title && (
                      <Text variant="h1" textShadow={TEXT_SHADOW_OUTLINE}>
                        {title}
                      </Text>
                    )}

                    {subtitle && (
                      <Text variant="h3" textShadow={TEXT_SHADOW_OUTLINE}>
                        {subtitle}
                      </Text>
                    )}

                    {ctaLabel && ctaUrl && (
                      <LightMode>
                        <Box>
                          <Href href={ctaUrl} target="_blank">
                            <Button colorScheme="brandPrimary">
                              <Text
                                as="span"
                                variant="button-lg"
                                color="inherit">
                                {ctaLabel}
                              </Text>
                            </Button>
                          </Href>
                        </Box>
                      </LightMode>
                    )}

                    {countSlides > 1 && (
                      <Stack direction="row" spacing="1rem">
                        <Box
                          backgroundColor="#fff"
                          borderRadius="full"
                          border="1px solid"
                          borderColor="gray.300">
                          <IconButton
                            borderRadius="full"
                            size="sm"
                            icon={
                              <Icon
                                icon="ms_chevron_left"
                                size="32px"
                                color="gray.500"
                              />
                            }
                            aria-label={intl.formatMessage({
                              defaultMessage: "Précédent",
                            })}
                            onClick={handleClickPrev}
                          />
                        </Box>

                        <Box
                          backgroundColor="#fff"
                          borderRadius="full"
                          border="1px solid"
                          borderColor="gray.300">
                          <IconButton
                            borderRadius="full"
                            size="sm"
                            icon={
                              <Icon
                                icon="ms_chevron_right"
                                size="32px"
                                color="gray.500"
                              />
                            }
                            aria-label={intl.formatMessage({
                              defaultMessage: "Suivant",
                            })}
                            onClick={handleClickNext}
                          />
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </CenteredContent>
              </Box>
            </DarkMode>
          </Box>
        </ConditionalWrapper>
      </CenteredContent>
    </Box>
  );
}

import { AspectRatio, Stack, Text } from "@chakra-ui/react";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Post").Post} post
 */
/**
 * @param {Props} props
 */
export function PageCard({ post }) {
  const translate = useTranslate();

  return (
    <Stack
      spacing="0"
      borderRadius="0.625rem"
      border="1px solid #eee"
      overflow="hidden"
      w="full"
      h="full">
      <AspectRatio ratio={16 / 9}>
        <PampaImage
          image={post.current_revision?.cover}
          placeholderSrc={generateFrontPath({
            id: "@front.internal-assets",
            parameters: {
              filePath: "placeholder-article-16-9.svg",
            },
            includeBasePath: true,
          })}
          maxContainerWidth={415}
          mode="crop"
          format="miniature"
          w="full"
          h="full"
          alt=""
        />
      </AspectRatio>

      <Stack
        spacing=".375rem"
        flexGrow={1}
        backgroundColor="#fff"
        py="1rem"
        px="1.5rem">
        <Text variant="button-md" noOfLines={1}>
          {translate(post.current_revision?.title)}
        </Text>

        {post.current_revision?.subtitle && (
          <Text variant="text-xs" noOfLines={2}>
            {translate(post.current_revision?.subtitle)}
          </Text>
        )}
      </Stack>
    </Stack>
  );
}

import { Box, Button, Link, Stack, Text, Wrap } from "@chakra-ui/react";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import {
  hotelsTypeMessage,
  HOTELS_TYPES_VALUE_LIST,
  HOTELS_TYPE_VALUE_COTTAGE,
} from "@raiden/library-ui/constants/hotels";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { memo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CenteredContent } from "../../../components/CenteredContent";
import { Href } from "../../../components/Href";
import { Icon } from "@raiden/library-ui/components/Icon";
import { SectionPublic } from "../../../components/SectionPublic";

export const Regions = memo(function Regions() {
  const intl = useIntl();

  const { configuration } = useConfiguration();

  const [selectedHotelType, setSelectedHotelType] = useState(
    /** @type {import("@raiden/library-ui/types/Hotel").HotelType} */ (
      HOTELS_TYPE_VALUE_COTTAGE
    ),
  );

  return (
    <SectionPublic
      title={intl.formatMessage({
        defaultMessage: "Nos hébergements par région",
      })}
      description={intl.formatMessage({
        defaultMessage:
          "Vous ne savez pas où aller ? Retrouvez les hébergements qui vous conviennent par région",
      })}>
      <Box px="1rem">
        <CenteredContent>
          <Stack spacing="1rem">
            <Wrap>
              {HOTELS_TYPES_VALUE_LIST.map((hotelType) => (
                <Button
                  key={hotelType.id}
                  onClick={() => {
                    setSelectedHotelType(hotelType.id);
                  }}
                  leftIcon={<Icon icon={hotelType.icon} />}
                  borderColor={
                    selectedHotelType === hotelType.id ? "#000" : "transparent"
                  }
                  size="sm"
                  borderRadius="full"
                  minW="2.5rem"
                  h="2.5rem"
                  variant="outline">
                  <Text variant="text-xs" color="inherit">
                    {hotelType.id === HOTELS_TYPE_VALUE_COTTAGE
                      ? intl.formatMessage({
                          defaultMessage: "Location de vacances",
                        })
                      : intl.formatMessage(hotelsTypeMessage, {
                          type: hotelType.id,
                        })}
                  </Text>
                </Button>
              ))}
            </Wrap>

            <RGrid minCellWidth="250px">
              {configuration.regions?.map((region) => (
                <Box key={region.id}>
                  <Text variant="text-md-link">{region.name}</Text>

                  <Href
                    href={generateFrontPath({
                      id: "@front.search",
                      query: {
                        types: [selectedHotelType],
                        location: { region_code: region.code },
                      },
                    })}>
                    <Link as="span">
                      <Text
                        as="span"
                        variant="text-sm"
                        colorScheme="brandPrimary">
                        <FormattedMessage
                          defaultMessage="{count, plural, =0 {# hébergement} one {# hébergement} other {# hébergements}}"
                          values={{
                            count:
                              region.count_hotels[selectedHotelType]
                                ?.count_hotels ?? 0,
                          }}
                        />
                      </Text>
                    </Link>
                  </Href>
                </Box>
              ))}
            </RGrid>
          </Stack>
        </CenteredContent>
      </Box>
    </SectionPublic>
  );
});

import { Slider } from "@raiden/library-ui/components/Slider";
import { CampaignCard } from "../../../components/Campaigns/Card";
import { CenteredContent } from "../../../components/CenteredContent";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Campaign").Campaign[]} campaigns
 */
/**
 * @param {Props} props
 */
export function SpecialOffers({ campaigns }) {
  return (
    <CenteredContent
      maxWidth={({ defaultMaxWidth }) => `calc(${defaultMaxWidth} + 2rem)`}>
      <Slider
        itemWidth={{
          base: "calc(100% - (2rem / 1))",
          sm: "calc(50% - (2rem / 2))",
          md: "calc(33.33% - (2rem / 3))",
          lg: "calc(25%)",
        }}>
        {campaigns.map((campaign) => (
          <CampaignCard campaign={campaign} aspectRatio={1} key={campaign.id} />
        ))}
      </Slider>
    </CenteredContent>
  );
}

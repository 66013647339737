import { CAMPAIGN_CATEGORIES_TYPE_VALUE_THEMATIC } from "@raiden/library-ui/constants/campaignCategories";

/**
 * @param {object} params
 * @param {import("@raiden/library-ui/types/Configuration").Configuration} params.configuration
 * @param {string} params.alias
 */
export function getConfigurationCampaignCategoryByAlias({
  configuration,
  alias,
}) {
  return configuration.campaigns_categories?.[
    CAMPAIGN_CATEGORIES_TYPE_VALUE_THEMATIC
  ]?.find((campaignCategory) => campaignCategory.alias === alias);
}

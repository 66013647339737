import {
  Box,
  Button,
  DarkMode,
  Flex,
  HStack,
  Image,
  LightMode,
  Stack,
  Text,
} from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { FormattedMessage } from "react-intl";
import { CenteredContent } from "../../../components/CenteredContent";
import { Icon } from "@raiden/library-ui/components/Icon";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import { Href } from "../../../components/Href";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";

export function Reinssurance() {
  const { configuration } = useConfiguration();
  const pageJoinLabelAlias = process.env.NEXT_PUBLIC_POST_PAGE_JOIN_LABEL_ALIAS;
  const translate = useTranslate();

  const postJoinLabel = configuration.posts?.page?.find((post) => {
    return pageJoinLabelAlias !== undefined
      ? post.alias === pageJoinLabelAlias
      : undefined;
  });

  return (
    <DarkMode>
      <Box textShadow="0 0 5px #000">
        <CenteredContent>
          <Flex
            minH={{ lg: "540px" }}
            borderRadius="10px"
            overflow="hidden"
            position="relative">
            <Box
              // background image
              position="absolute"
              backgroundColor={{
                base: "rgba(0, 37, 56, 0.40)",
                lg: "rgba(0, 37, 56, 0.10)",
              }}
              inset="0">
              <Image
                src={generateFrontPath({
                  id: "@front.internal-assets",
                  parameters: { filePath: "reinsurance-home_1_.jpg" },
                  includeBasePath: true,
                })}
                w="full"
                h="full"
                objectFit="cover"
                alt=""
              />
            </Box>

            <Box
              // overlay 1
              position="absolute"
              backgroundColor={{
                base: "rgba(0, 37, 56, 0.40)",
                lg: "rgba(0, 37, 56, 0.10)",
              }}
              inset="0"
            />

            <Box
              // overlay 2
              position="absolute"
              backgroundColor="rgba(14, 147, 212, 0.10)"
              inset="0"
            />

            <Stack
              spacing={{ base: "2rem", lg: undefined }}
              direction={{ base: "column", lg: "row" }}
              alignItems="stretch"
              py={{ base: "1.25rem", lg: undefined }}>
              <HStack
                justify={{ base: "center", lg: undefined }}
                pt={{ base: "20px", lg: undefined }}
                pl={{ lg: "3.4375rem" }}
                pr={{ lg: "4.375rem" }}>
                <Image
                  src={generateFrontPath({
                    id: "@front.internal-assets",
                    parameters: { filePath: "logo-white.svg" },
                    includeBasePath: true,
                  })}
                  filter="drop-shadow(0 0 5px #000)"
                  alt="Clévacances"
                  w="250px"
                  maxW="full"
                  loading="lazy"
                />
              </HStack>

              <HStack
                flexGrow={1}
                pl={{ base: "0.625rem", lg: "2.75rem" }}
                pr={{ base: "0.625rem", lg: undefined }}
                backdropFilter="blur(1px)">
                <Stack spacing="1rem">
                  <Text variant="h2">
                    <FormattedMessage defaultMessage="Adhérez au label Clévacances" />
                  </Text>

                  <Text variant="h5">
                    <FormattedMessage defaultMessage="Comme 8000 propriétaires, rejoignez notre label de qualité !" />
                  </Text>

                  <Stack spacing="1.5rem" color="#fff">
                    <HStack spacing="1rem">
                      <Icon icon="ms_star_border" size="1.5rem" />

                      <Text variant="text-md">
                        <FormattedMessage defaultMessage="Un label de qualité qui rassure les vacanciers" />
                      </Text>
                    </HStack>

                    <HStack spacing="1rem">
                      <Icon icon="ms_help_outline" size="1.5rem" />

                      <Text variant="text-md">
                        <FormattedMessage defaultMessage="Un SAV juridique et fiscal" />
                      </Text>
                    </HStack>

                    <HStack spacing="1rem">
                      <Icon icon="ms_support_agent" size="1.5rem" />

                      <Text variant="text-md">
                        <FormattedMessage defaultMessage="Un technicien référent vous accompagne au quotidien" />
                      </Text>
                    </HStack>

                    <HStack spacing="1rem">
                      <Icon icon="ms_rocket" size="1.5rem" />

                      <Text variant="text-md">
                        <FormattedMessage defaultMessage="Optimisation de votre activité et de votre visibilité" />
                      </Text>
                    </HStack>

                    {postJoinLabel && (
                      <Box>
                        <LightMode>
                          <Href
                            href={generateFrontPath({
                              id: "@front.page.view",
                              parameters: {
                                postSlug: `${postJoinLabel.id}-${translate(
                                  postJoinLabel.slug,
                                )}`,
                              },
                            })}>
                            <Button
                              size="lg"
                              borderRadius="10px"
                              colorScheme="brandSecondary">
                              <Text
                                as="span"
                                color="inherit"
                                variant="button-lg">
                                <FormattedMessage defaultMessage="J'adhère au label" />
                              </Text>
                            </Button>
                          </Href>
                        </LightMode>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </HStack>
            </Stack>
          </Flex>
        </CenteredContent>
      </Box>
    </DarkMode>
  );
}

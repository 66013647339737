// Tris
import { defineMessage } from "react-intl";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_RESTORE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "./policies";

export const CAMPAIGNS_CATEGORIES_SORT_VALUE_CREATED_ASC = "created_asc";
export const CAMPAIGNS_CATEGORIES_SORT_VALUE_CREATED_DESC = "created_desc";
export const CAMPAIGNS_CATEGORIES_VALUE_TITLE_ASC = "title_asc";
export const CAMPAIGNS_CATEGORIES_VALUE_TITLE_DESC = "title_desc";

export const CAMPAIGNS_CATEGORIES_SORTS = {
  [CAMPAIGNS_CATEGORIES_SORT_VALUE_CREATED_ASC]: {
    id: CAMPAIGNS_CATEGORIES_SORT_VALUE_CREATED_ASC,
  },
  [CAMPAIGNS_CATEGORIES_SORT_VALUE_CREATED_DESC]: {
    id: CAMPAIGNS_CATEGORIES_SORT_VALUE_CREATED_DESC,
  },
  [CAMPAIGNS_CATEGORIES_VALUE_TITLE_ASC]: {
    id: CAMPAIGNS_CATEGORIES_VALUE_TITLE_ASC,
  },
  [CAMPAIGNS_CATEGORIES_VALUE_TITLE_DESC]: {
    id: CAMPAIGNS_CATEGORIES_VALUE_TITLE_DESC,
  },
};

export const CAMPAIGNS_CATEGORIES_SORT_LIST = Object.values(
  CAMPAIGNS_CATEGORIES_SORTS,
);

export const CAMPAIGNS_CATEGORIES_SORT_MESSAGE = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} title_asc {Titre croissant} title_desc {Titre décroissant} other {{sort}}}",
});

export const CAMPAIGN_CATEGORIES_TYPE_VALUE_THEMATIC = "thematic";
export const CAMPAIGN_CATEGORIES_TYPE_VALUE_DESTINATION = "destination";

export const CAMPAIGN_CATEGORIES_TYPES = {
  [CAMPAIGN_CATEGORIES_TYPE_VALUE_THEMATIC]: {
    id: CAMPAIGN_CATEGORIES_TYPE_VALUE_THEMATIC,
  },
  [CAMPAIGN_CATEGORIES_TYPE_VALUE_DESTINATION]: {
    id: CAMPAIGN_CATEGORIES_TYPE_VALUE_DESTINATION,
  },
};

export const CAMPAIGN_CATEGORIES_TYPES_LIST = Object.values(
  CAMPAIGN_CATEGORIES_TYPES,
);

export const CAMPAIGN_CATEGORIES_POLICIES_ACTIONS = {
  restore: {
    policies: [POLICIES_POLICY_VALUE_RESTORE],
  },
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_SOFT_DELETE,
      POLICIES_POLICY_VALUE_DELETE,
    ],
  },
};
